import './App.css';
import {Provider} from "react-redux";
import store from "./reducers";
import {BrowserRouter} from "react-router-dom";
import RoutePage from "./routes";
import MainDashboardComponent from "./components/main.component";

function App() {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <MainDashboardComponent content={<RoutePage/>}/>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
